const faqs = [
  {
    id: 1,
    question: "Is this billed annually or monthly?",
    answer:
      "All billing is monthly. If you prefer an annual payment, that can be facilitated. Contact support for this.",
  },
  {
    id: 2,
    question: "Is there a trial?",
    answer:
      "Yes. We offer a two week free trial and we will email you before the trial ends.",
  },
  {
    id: 3,
    question: "I need a larger plan. Is that possible?",
    answer:
      "Yes. We're happy to create custom plans to fit your needs. Email sales@devscreen.io with more information and we'll build a plan for you.",
  },
  {
    id: 4,
    question: "Can I use my own tests?",
    answer:
      "Absolutely. Email support@devscreen.io and we will add the test to your account so you can use it in your interviews.",
  },
  {
    id: 5,
    question: "Do you assess code submissions?",
    answer:
      "Yes, though this is not mandatory if you prefer to do this yourself. One of our engineers will review the submission and write a report for you. This is an additional cost of $35 per test.",
  },
  {
    id: 6,
    question: "Does DevScreen integrate with any Applicant Tracking System (ATS)?",
    answer:
      "We currently integrations in beta and are adding more soon, such as Lever and JazzHR. If there's an integration you need that's not listed, please get in touch.",
  },
]

export default function FaqSection() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center">Frequently asked questions</h2>
        <div className="mt-12">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="text-lg leading-6 font-medium text-gray-900">{faq.question}</dt>
                <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
