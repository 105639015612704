/* This example requires Tailwind CSS v2.0+ */
import { ClockIcon, ShieldCheckIcon, TerminalIcon } from '@heroicons/react/outline'
import prScreenGrab from '../../assets/images/prscreen.png'

const transferFeatures = [
  {
    id: 1,
    name: 'Save yourself time and money',
    description:
      'We handle it all - from creating the tests to assessing them. We take time to understand what\'s improtant to your organisation and we ensure that is reflected in the way we assess candidates.',
    icon: ClockIcon,
  },
  {
    id: 2,
    name: 'Avoid leaks',
    description:
      'With DevScreen, a candidate cannot accidentally put your coding challenges on their public GitHub account for the world to see. This ensures that candidates are seeing your challenge for the first time, every time.',
    icon: ShieldCheckIcon,
  },
  {
    id: 3,
    name: 'Realistic tests',
    description:
      'This works both ways: we allow candidates to use the tools that they will use when working for you, and we provide you with real-world challenges to give candidates to ensure you hire the best.',
    icon: TerminalIcon,
  },
]

export default function FeatureShowcase() {
  return (
    <div className="py-16 lg:py-20">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Familiar tools. Realistic tasks.
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              Test your candidates based on what they will do on the job: review another person's code, fix bugs in an existing code base, or complete a project.
              We integrate closely with Github to provide a secure environment that is familiar to both the candidate and the reviewer.
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <img
              className="relative mx-auto"
              width={490}
              src={prScreenGrab}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}
