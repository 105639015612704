import SiteFooter from '../footer/footer.component'
import SiteHeader from '../header/header.component'

export default function LayoutComponent(props: any) {
  return (
    <div className="bg-white">
      <SiteHeader />
        <main>
          {props.children}
        </main>
      <SiteFooter />
    </div>
  )
}
