import { CheckIcon, ThumbUpIcon, UserIcon } from '@heroicons/react/solid'

const timeline = [
  {
    id: 1,
    content: 'Candidate applies for a job',
    icon: UserIcon,
    iconBackground: 'bg-gray-400',
  },
  {
    id: 2,
    content: 'You create an interview on DevScreen',
    icon: ThumbUpIcon,
    iconBackground: 'bg-blue-500',
  },
  {
    id: 3,
    content: 'We create a private Github project for the candidate to use',
    icon: CheckIcon,
    iconBackground: 'bg-green-500',
  },
  {
    id: 4,
    content: 'We send the candidate instructions on how to get started',
    icon: ThumbUpIcon,
    iconBackground: 'bg-blue-500',
  },
  {
    id: 5,
    content: 'We keep you updated on their progress as they work through the problem',
    icon: CheckIcon,
    iconBackground: 'bg-green-500',
  },
  {
    id: 6,
    content: 'Once completed, your team (or DevScreen, if you wish) will be added to the private GitHub project and review the submission',
    icon: CheckIcon,
    iconBackground: 'bg-green-500',
  },
  {
    id: 7,
    content: 'Now it\'s time to make the hiring decision!',
    icon: CheckIcon,
    iconBackground: 'bg-green-500',
  },
]

export default function HowItWorksTimeline() {
  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className='h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-indigo-600'
                  >
                    <span className="h-5 w-5 text-white text-center mb-1" aria-hidden="true">{event.id}</span>
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm text-gray-500">
                      {event.content}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>)
}
