import LayoutComponent from '../components/layout/layout.component';
import HeroSection from "../components/hero-section/hero-section.component";
import Pricing from "../components/pricing/pricing.component";
import FeatureShowcase from "../components/feature-showcase/feature-showcase.component";
import Testimonial from "../components/testimonials/testimonials.component";
import NewFeatures from '../components/new-features/new-features.component';
import DemoVideo from '../components/demo-video/demo-video.component';

export default function HomePage() {
  return (
    <LayoutComponent>
      <HeroSection />
      <DemoVideo />
      <FeatureShowcase />
      <NewFeatures />
      <Testimonial />
      <Pricing />
    </LayoutComponent>
  );
}
