import FaqSection from "../components/faq-section/faq-section.component";
import LayoutComponent from "../components/layout/layout.component";
import Pricing from "../components/pricing/pricing.component";

export default function PricingPage() {

  return (
    <LayoutComponent>
      <Pricing />
      <FaqSection />
    </LayoutComponent>
  )
}
