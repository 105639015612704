import { VFile } from "vfile"

interface InclusiveJdResultsProps {
  analysis: VFile | undefined;
}

export default function InclusiveJdResults(props: InclusiveJdResultsProps) {
  if (props.analysis?.messages?.length === 0) {
    return (<p>Congratulations - no issues found! ✨</p>)
  }
  return (<ul className="divide-y divide-gray-200 max-w-3xl">
    {props.analysis?.messages.map((message) => (
      <li
        key={`${message.source}-${message.name}`}
        className="relative bg-white py-5 px-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50"
      >
        <div className="flex justify-between space-x-3">
          <div className="min-w-0 flex-1">
            <a href={`#${message.actual}-${0}-${message.line}`} className="block focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="truncate text-sm font-medium text-gray-900">Line {message.line}</p>
            </a>
          </div>
        </div>
        <div className="mt-1">
          <p className="text-sm text-gray-600 line-clamp-2">{message.message}</p>
        </div>
      </li>
    ))}
  </ul>
)
}
