import { Link } from "react-router-dom";
import LayoutComponent from "../../components/layout/layout.component";

export default function PredictableResultsPage() {
  return (
    <LayoutComponent>
      <div className="py-16 bg-gray-50 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-none">
            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Use-cases</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Find the best engineers
            </p>
          </div>
          <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <p className="text-gray-500">
              The whole point of an interview is to understand if they will be able to do the job and contribute well to our code.
              Despite this, companies are still asking questions in the interview that are totally unrelated to the job, so much so that Stanford run a whole <a className="underline" href="https://web.stanford.edu/class/cs9/" target="_blank">course</a> focused solely on the technical interview!
              DevScreen is changing the way we assess engineers for the better.
            </p>
          </div>
          <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <div className="relative">
              <div className="prose prose-indigo text-gray-500 mx-auto lg:max-w-none">
                <p>
                  Instead of asking a candidate to invert a binary tree or implement some variant of a merge sort algorithm,
                  we provide a library of awesome, real-world tests that look more like this:
                </p>
                <ul className="my-4 px-4">
                  <li>Review a pull request from a junior colleague and provide high quality feedback and mentorship.</li>
                  <li>Fix a bug in an existing code repository and open up a pull-request with the solution.</li>
                  <li>Given a skeleton code project, build a new feature.</li>
                </ul>

              </div>
              <h3 className="mt-10 text-base text-indigo-600 font-semibold">Ready to try it out?</h3>
              <div className="mt-5 flex text-base max-w-prose mx-auto lg:max-w-none">
                <div className="rounded-md shadow ">
                  <Link
                    to={'/pricing'}
                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    View pricing
                  </Link>
                </div>
                <div className="rounded-md shadow ml-4">
                  <a
                    href="mailto:sales@devscreen.io?subject=I would like a demo of DevScreen"
                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50"
                  >
                    Talk to sales
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
}
