import { useEffect, useState } from "react";
import * as contentful from 'contentful';
import LayoutComponent from "../../components/layout/layout.component";
import { Link } from "react-router-dom";
import { BlogPost } from "../../models/blog-entry.interface";
import { getAltTextForCoverImage, getCoverImageUrlForBlogPost } from "../../services/contentful-parser.service";

export default function BlogListPage() {
  const [blogPosts, setBlogPosts] = useState<contentful.EntryCollection<BlogPost>>();
  const client = contentful.createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: "bkgv1u9dbth3",
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: "bI_RAN7MvZ-6m6ATkNsnZm1g_FvsaDxrT5s4vF6PHQg"
  });
  useEffect(() => {
    client
      .getEntries<BlogPost>()
      .then(entries => {
        setBlogPosts(entries)
      })
      .catch(err => console.log(err));
  }, [])

  return (
    <LayoutComponent>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto pb-16 px-4 sm:px-6 lg:pb-20 lg:px-8">
          <div className="relative px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
            <div className="absolute inset-0">
              <div className="h-1/3 bg-white sm:h-2/3" />
            </div>
            <div className="relative mx-auto max-w-7xl">
              <div className="text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">DevScreen blog</h2>
                <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
                  We talk about interviews, hiring and tech.
                </p>
              </div>
              <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
                {blogPosts?.items.map((blogPost, i) => (
                  <div key={blogPost.fields.title} className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                    <div className="flex-shrink-0">
                      <img className="h-48 w-full object-cover" src={getCoverImageUrlForBlogPost(blogPost)} alt={getAltTextForCoverImage(blogPost)} />
                    </div>
                    <div className="flex flex-1 flex-col justify-between bg-white p-6">
                      <div className="flex-1">
                        <p className="text-sm font-medium text-indigo-600">
                          <Link to={`/blog/${blogPost.sys.id}`} className="hover:underline">
                            {blogPost.fields.blogType}
                          </Link>
                        </p>
                        <Link to={`/blog/${blogPost.sys.id}`} className="mt-2 block">
                          <p className="text-xl font-semibold text-gray-900">{blogPost.fields.title}</p>
                          <p className="mt-3 text-base text-gray-500">{blogPost.fields.description}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>
      </div>
    </LayoutComponent>
  );
}
