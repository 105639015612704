import React from "react";
import LayoutComponent from '../components/layout/layout.component';

export default function AboutPage() {
  return (
    <LayoutComponent>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto pb-16 px-4 sm:px-6 lg:pb-20 lg:px-8">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 mb-12">
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 pb-4">
              <div className="pt-12 sm:pt-16 lg:pt-20">
                <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl text-center">
                  On a mission to help companies to hire the best software engineers
                </h2>
                <div className="mt-6 text-gray-500 space-y-6">
                  <p className="text-lg text-justify">
                    Founded in 2021, DevScreen was created to help companies to improve their interview process for
                    software engineers and transform the Candidate Experience (CX).
                  </p>
                  <p className="text-base leading-7 text-justify">
                    Whiteboard interviews and classic programming interview challenges are not a good predictor of a candidate's
                    abilities. We believe that there's a better way. We believe that using realistic problems and letting
                    candidates use familiar tools will result in better CX for the candidate and a better new hire for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="divide-y-2 divide-gray-200">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">How to contact us</h2>
              <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Sales</h3>
                  <dl className="mt-2 text-base text-gray-500">
                    <div>
                      <dt className="sr-only">Email</dt>
                      <dd><a href="mailto:sales@devscreen.io">sales@devscreen.io</a></dd>
                    </div>
                  </dl>
                </div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Support</h3>
                  <dl className="mt-2 text-base text-gray-500">
                    <div>
                      <dt className="sr-only">Email</dt>
                      <dd><a href="mailto:support@devscreen.io">support@devscreen.io</a></dd>
                    </div>
                  </dl>
                </div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Say hello 👋</h3>
                  <dl className="mt-2 text-base text-gray-500">
                    <div>
                      <dt className="sr-only">Email</dt>
                      <dd><a href="mailto:info@devscreen.io">info@devscreen.io</a></dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
}
