import { useState } from "react";
import retextEnglish from "retext-english";
import retextEquality from "retext-equality";
import retextProfanities from "retext-profanities";
import retextStringify from "retext-stringify";
import { unified } from "unified";
import { VFile } from "vfile";
import InclusiveJdResults from "../../components/inclusive-jd-results/inclusive-jd-results.component";
import LayoutComponent from "../../components/layout/layout.component";

export default function InclusiveLanguageCheckPage() {
  const [jobDescription, setJobDescription] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [jdAnalysis, setJdAnalysis] = useState<VFile>();

  const handleSubmit = async () => {
    setJdAnalysis(new VFile());

    const file = await unified()
        .use(retextEnglish)
        .use(retextProfanities)
        .use(retextEquality)
        .use(retextStringify)
        .process(new VFile(jobDescription));

    setJdAnalysis(file);
    setIsEditing(false);
  }
  function escapeRegExp(s: string) {
    return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  const processJdAnalysis = () => {

    return jobDescription
      .split('\n')
      .map((piece, i) => {
        const messagesForLine = jdAnalysis?.messages.filter(msg => msg.position?.start.line === i+1);
        if (messagesForLine?.length && messagesForLine?.length > 0) {
          let s: string = piece
          s = s
              .split(
                new RegExp(
                  `\\b${messagesForLine.map(m => escapeRegExp(m.actual!)).join('|')}\\b`,
                  'g'
                )
              )
              .flatMap((item: any, i) => {
                return [item, (messagesForLine[i]?.actual ? <div id={`${messagesForLine[i]?.actual}-${i}-${messagesForLine[i]?.line}`} key={`${messagesForLine[i]?.actual}-${i}-${messagesForLine[i]?.line}`} className='inline bg-red-100 text-red-700 p-1 rounded'><span>{messagesForLine[i]?.actual}</span></div> : null)]
              }) as unknown as string

          return (<p key={`jd-analysis-piece-${i}`}>
            {s}
            </p>);
        } else {
          return (<p key={`jd-analysis-piece-${i}`}>
            {piece}
          </p>);
        }
      });
  };

  return (
    <LayoutComponent>
      <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
        <div className="relative bg-white shadow-xl">
          <h2 className="sr-only">Contact us</h2>

          <div className="grid grid-cols-1 lg:grid-cols-3">
            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 className="text-lg font-medium text-gray-900">Check your job description for non-inclusive language</h3>
              {isEditing ? <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="message" className="block text-sm font-medium text-gray-900">
                      Job Description
                    </label>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      rows={10}
                      className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      aria-describedby="message-max"
                      value={jobDescription}
                      onChange={(e) => setJobDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2 sm:flex sm:justify-end">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Submit
                  </button>
                </div>
              </div> : <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="message" className="block text-sm font-medium text-gray-900">
                      Job Description
                    </label>
                  </div>
                  <div className="mt-1">
                    <div className="whitespace-pre-wrap	">
                      {processJdAnalysis()}
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-2 sm:flex sm:justify-end">
                  <button
                    type="button"
                    onClick={() => setIsEditing(true)}
                    className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Edit
                  </button>
                </div>
              </div>}
            </div>

            <div className="relative overflow-hidden bg-indigo-700 py-10 px-6 sm:px-10 xl:p-12">
              <div className="pointer-events-none absolute inset-0 sm:hidden" aria-hidden="true">
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={343}
                  height={388}
                  viewBox="0 0 343 388"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                    fill="url(#linear1)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear1"
                      x1="254.553"
                      y1="107.554"
                      x2="961.66"
                      y2="814.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={359}
                  height={339}
                  viewBox="0 0 359 339"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                    fill="url(#linear2)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear2"
                      x1="192.553"
                      y1="28.553"
                      x2="899.66"
                      y2="735.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={160}
                  height={678}
                  viewBox="0 0 160 678"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                    fill="url(#linear3)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear3"
                      x1="192.553"
                      y1="325.553"
                      x2="899.66"
                      y2="1032.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h3 className="text-lg font-medium text-white">About our job description checker</h3>
              <p className="mt-6 max-w-3xl text-base text-indigo-50">
                Research has shown that non-inclusive language discourages applicants from diverse backgrounds from applying for a position.
              </p>
              <p className="mt-6 max-w-3xl text-base text-indigo-50">
                This tool will find and highlight instances of language that is insensitive or non-inclusive. Paste your job description into the text box and submit it to see the results.
              </p>
              <p className="mt-6 max-w-3xl text-base text-indigo-50">
                Please note that the tool may give some false positives as many words are only non-inclusive in certain contexts. You should use your best judgement to decide what rules to enforce.
              </p>
            </div>
          </div>
        </div>
        {!!jdAnalysis ? <h3 className="my-3 text-xl py-2 font-medium leading-6 text-gray-900">Results</h3> : null}
        <InclusiveJdResults analysis={jdAnalysis} />
      </div>
    </LayoutComponent>
  );
}
