import { CodeIcon, DocumentSearchIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";


const features = [
  {
    icon: DocumentSearchIcon,
    name: 'Inclusive job description checker',
    description: 'Are your job descriptions inclusive? Get immediate analysis with our free job description inclusivity checker.',
    toRoute: '/inclusive-language-check',
    isExternal: false,
  },
  {
    icon: CodeIcon,
    name: 'Developer tools',
    description: 'Free, open-source developer tools so you can safely and securely format JSON data, convert to YAML, generate UUIDs and more.',
    toRoute: 'https://devtools.devscreen.io',
    isExternal: true,
  }
];
export default function NewFeatures() {
  return (
    <div className="mx-auto max-w-7xl bg-white py-6 px-6 lg:px-8">
      <h3 className="text-xl text-center font-bold tracking-tight text-gray-900 sm:text-3xl sm:leading-none lg:text-4xl">
        Have you seen our new resources?
      </h3>
      <p className="mt-6 text-lg text-gray-500 text-center">
        We're here to make software engineer's lives easier. Check out some of the new tools we've released.
      </p>

      <div className="mx-auto mt-16 max-w-2xl lg:max-w-4xl">
        <dl className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900 underline">
                <div className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                  <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                {feature.isExternal ? <a href={feature.toRoute} target="_blank">{feature.name}</a> : <Link to={feature.toRoute}>{feature.name}</Link>}
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
};
