/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/solid'

const pricing = {
  tiers: [
    {
      id: 'small-business',
      title: 'Small business',
      price: 59,
      frequency: '/month',
      description: 'Perfect for small companies. Scale up instantly when needed.',
      features: [
        'Up to 5 users (admins, hiring managers & reviewers)',
        '10 interviews per week',
        'Basic analytics',
        '48-hour support response time'
      ],
      cta: 'Sign up',
      mostPopular: false,
    },
    {
      id: 'medium-business',
      title: 'Medium enterprise',
      price: 99,
      frequency: '/month',
      description: 'A plan that scales with your rapidly growing business.',
      features: [
        'Up to 50 users (admins, hiring managers & reviewers)',
        'Unlimited interviews',
        'Detailed interview progress tracking',
        '24-hour support response time',
        'Slack notifications',
      ],
      cta: 'Sign up',
      mostPopular: true,
    },
    {
      id: 'large-business',
      title: 'Large enterprise',
      price: 249,
      frequency: '/month',
      description: 'Dedicated customer support and enterprise features.',
      features: [
        'Unlimited users',
        'Unlimited interviews',
        'Email and dedicated Slack-channel support',
        'Single sign-on',
        'Slack & email notifications',
      ],
      cta: 'Get quote',
      mostPopular: false,
    },
  ],
}

export default function Pricing() {
  return (
    <div className="mx-auto max-w-7xl bg-white py-24 px-6 lg:px-8">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">
        Flexible pricing with a 2-week free trial
      </h2>
      <p className="mt-6 max-w-2xl text-xl text-gray-500">
        Choose the right plan for you. If you'd prefer to pay per interview, email us <a href="mailto:sales@devscreen.io">sales@devscreen.io</a>
      </p>

      {/* Tiers */}
      <div className="mt-24 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">{tier.title}</h3>
              {tier.mostPopular ? (
                <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-indigo-500 py-1.5 px-4 text-sm font-semibold text-white">
                  Most popular
                </p>
              ) : null}
              <p className="mt-4 flex items-baseline text-gray-900">
                {tier.id === 'large-business' ? <span className="ml-1 text-xl font-semibold">From &nbsp;</span> : <></>}
                <span className="text-5xl font-bold tracking-tight">${tier.price}</span>
                <span className="ml-1 text-xl font-semibold">{tier.frequency}</span>
              </p>
              <p className="mt-6 text-gray-500">{tier.description}</p>

              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex">
                    <CheckIcon className="h-6 w-6 flex-shrink-0 text-indigo-500" aria-hidden="true" />
                    <span className="ml-3 text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <a
              href={tier.id === 'large-business' ? 'mailto:sales@devscreen.io' : `https://app.devscreen.io/?plan=${tier.id}`}
              className={'bg-indigo-500 text-white hover:bg-indigo-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
            >
              {tier.cta}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
};
