import { Link } from 'react-router-dom';
import screenshot from '../../assets/images/admin-home-screenshot.png';

export default function HeroSection() {
  return (
    <div className="bg-white">
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:pb-48">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div className="mt-20">
              <div className="mt-6 sm:max-w-xl">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  <span
                    className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-5xl"
                  >
                    <span className="block text-gray-900">Developer interview tests</span>
                    <span className="block text-indigo-600">for the real world.</span>
                  </span>
                </h1>
                <p className="mt-6 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  Hire better engineers, faster. Our carefully created real-world problems provide a true measure
                  of a candidate's ability to do the job. Our experienced assessors free up your engineers from correcting
                  candidate's submissions to work on what's most important for you.
                </p>
              </div>
            </div>
            <div className="mt-5 flex text-base max-w-prose mx-auto lg:max-w-none">
            <div className="rounded-md shadow ">
                <Link
                  to={'/pricing'}
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  View pricing
                </Link>
              </div>
              {/* <div className="rounded-md shadow ml-4">
                <a
                  href='https://demo-app.devscreen.io'
                  target={'_blank'}
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  See the demo
                </a>
              </div> */}
              <div className="rounded-md shadow ml-4">
                <a
                  href="mailto:sales@devscreen.io?subject=I would like a demo of DevScreen"
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50"
                >
                  Talk to sales
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
              </svg>
            </div>
            <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
              <img
                className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                src={screenshot}
                alt="Screenshot of the product"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
