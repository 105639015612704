import React, { useEffect, useState } from "react";
import * as contentful from 'contentful';
import LayoutComponent from "../../components/layout/layout.component";
import { useParams } from "react-router-dom";
import { BlogPost } from "../../models/blog-entry.interface";
import { getDescriptionForEntry, getJSXForRichTextEntryContent, getTitleForEntry, getCoverImageUrlForBlogPost } from "../../services/contentful-parser.service";

export default function BlogEntryPage() {
  let { entryId } = useParams<{entryId: string}>();
  const [blogPost, setBlogPost] = useState<contentful.Entry<BlogPost>>();
  const client = contentful.createClient({
    space: "bkgv1u9dbth3",
    accessToken: "bI_RAN7MvZ-6m6ATkNsnZm1g_FvsaDxrT5s4vF6PHQg"
  });
  useEffect(() => {
    if (entryId) {
      client
        .getEntry<BlogPost>(entryId)
        .then(entry => {
          setBlogPost(entry)
          document.
            querySelector('meta[name="twitter:card"]')?.setAttribute("content", "summary_large_image");
          document.
            querySelector('meta[name="twitter:site"]')?.setAttribute("content", "@devscreen_app");
          document.
            querySelector('meta[name="twitter:title"]')?.setAttribute("content", getTitleForEntry(entry));
          document.
            querySelector('meta[name="twitter:description"]')?.setAttribute("content", getDescriptionForEntry(entry));
          document.
            querySelector('meta[name="twitter:image"]')?.setAttribute("content", getCoverImageUrlForBlogPost(entry));
          document.
            querySelector('meta[name="og:title"]')?.setAttribute("content", getTitleForEntry(entry));
          document.
            querySelector('meta[name="og:description"]')?.setAttribute("content", getDescriptionForEntry(entry));
          document.
            querySelector('meta[name="og:image"]')?.setAttribute("content", getCoverImageUrlForBlogPost(entry));
        })
        .catch(err => console.log(err));
    }
  }, [entryId])
  return (
    <LayoutComponent>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto pb-16 px-4 sm:px-6 lg:pb-20 lg:px-8">
          <div className="relative overflow-hidden bg-white py-16">
            <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
              <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
                <svg
                  className="absolute top-12 left-full translate-x-32 transform"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                </svg>
                <svg
                  className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                </svg>
                <svg
                  className="absolute bottom-12 left-full translate-x-32 transform"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-prose text-lg">
                <h1>
                  <span className="block text-center text-lg font-semibold text-indigo-600 capitalize">{blogPost?.fields.blogType}</span>
                  <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                    {blogPost ? getTitleForEntry(blogPost) : ''}
                  </span>
                </h1>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  {blogPost ? getDescriptionForEntry(blogPost) : ''}
                </p>
              </div>
              <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
                {blogPost?.fields.body.content.map((content, i) => {
                  const key = `${entryId}-${i}`
                  return (<div key={key}>
                    {getJSXForRichTextEntryContent(content)}
                  </div>)
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
}
