import { CodeIcon, DocumentSearchIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";


const features = [
  {
    icon: DocumentSearchIcon,
    name: 'Inclusive job description checker',
    description: 'Are your job descriptions inclusive? Get immediate analysis with our free job description inclusivity checker.',
    toRoute: '/inclusive-language-check',
    isExternal: false,
  },
  {
    icon: CodeIcon,
    name: 'Developer tools',
    description: 'Free, open-source developer tools so you can safely and securely format JSON data, convert to YAML, generate UUIDs and more.',
    toRoute: 'https://devtools.devscreen.io',
    isExternal: true,
  }
];
export default function DemoVideo() {
  return (
    <div className="mx-auto max-w-7xl bg-white py-6 px-6 lg:px-8">
      <h3 className="text-xl text-center font-bold tracking-tight text-gray-900 sm:text-3xl sm:leading-none lg:text-4xl">
        See it in action
      </h3>
      <div className="mx-auto mt-16 max-w-2xl lg:max-w-4xl flex justify-center	">
        <div style={{width:560}}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/P0jwPGm8_UQ?controls=0&modestbranding=1&playsinline=1&color=white&frameborder=0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </div>
      </div>
    </div>
  )
};
