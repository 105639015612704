export default function Testimonial() {
  return (
    <div className="bg-white py-16 lg:py-24">
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden rounded-xl bg-indigo-500 py-24 px-8 shadow-2xl lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-16">
          <div className="absolute inset-0 opacity-50 mix-blend-multiply saturate-0 filter">
            <img
              src="https://ds-public-images.s3.us-east-1.amazonaws.com/engineering-splash-bg.jpg"
              alt=""
              className="h-full w-full object-cover"
            />
          </div>
          <div className="relative lg:col-span-1">
            <span className="rounded opacity-70 bg-indigo-50 px-2 py-1.5 text-sm text-indigo-700 tracking-wide">
              Candidate testimonials
            </span>
            <blockquote className="mt-6 text-white">
              <p className="text-xl font-medium sm:text-2xl">
                This is the first interview test I've every done that actually looked like the day-to-day work of a software engineer.
              </p>
              <footer className="mt-6">
                <p className="flex flex-col font-medium">
                  <span>- Senior backend engineer candidate</span>
                </p>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  )
}
