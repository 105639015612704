import { Asset, Entry, RichTextContent } from "contentful";
import { BlogPost } from "../models/blog-entry.interface";

export function getTitleForEntry(entry: Entry<BlogPost>): string  {
  return entry.fields.title || '';
}

export function getDescriptionForEntry(entry: Entry<BlogPost>): string  {
  return entry.fields.description || '';
}

export function getCoverImageUrlForBlogPost(entry: Entry<BlogPost>): string  {
  return entry.fields.coverPhoto.fields.file.url || '';
}

export function getAltTextForCoverImage(entry: Entry<BlogPost>): string  {
  return entry.fields.coverPhoto.fields.title || '';
}

export function getJSXForRichTextEntryContent(entryContent: RichTextContent): JSX.Element {
  const nodeType = entryContent.nodeType as string;

  switch (nodeType) {
    case 'paragraph':
      return getParagraphJSX(entryContent)
    case 'ordered-list':
      return getOrderedListJSX(entryContent);
    case 'unordered-list':
      return getUnorderedListJSX(entryContent);
    case 'blockquote':
      return getBlockQuoteJSX(entryContent);
    case 'heading-1':
      return getHeading1JSX(entryContent);
    case 'heading-2':
      return getHeading2JSX(entryContent);
    case 'heading-3':
      return getHeading3JSX(entryContent);
    case 'heading-4':
      return getHeading4JSX(entryContent);
    case 'heading-5':
      return getHeading5JSX(entryContent);
    case 'heading-6':
      return getHeading6JSX(entryContent);
    case 'embedded-asset-block':
      return geEmbeddedImageJSX(entryContent);
    case 'embedded-entry-block':
    case 'asset-hyperlink':
    default:
      console.log("NO DATA", entryContent)
      return <></>;
  }
}

function getParagraphJSX(entryContent: RichTextContent): JSX.Element {
  if (!entryContent || (entryContent && !entryContent.content)) {
    return <></>;
  }

  return <p>{entryContent
    ?.content
    ?.map((content, i) => {
      if (content.nodeType === 'hyperlink') {
        const str = content.content?.map(c => c.value).join(' ');
        return <a key={`para-js-${content.nodeType}-${i}-${str?.substring(0, 5)}`} href={content.data.uri} target={'_blank'}>{str}</a>
      }
      return <span key={`para-js-${content.nodeType}-${i}-${content.value?.substring(0, 5)}`}>{content.value}</span>
    })}</p>
}

function getOrderedListJSX(entryContent: RichTextContent): JSX.Element {
  if (!entryContent || (entryContent && !entryContent.content)) {
    return <></>;
  }

  return <ol>
    {entryContent?.content?.map((content, i) => {
      return content?.content?.map((listItemContent) => {
        return <li key={`ordered-list-${i}-${listItemContent?.value?.substring(0, 30)}`}>{getJSXForRichTextEntryContent(listItemContent)}</li>
      });
    })}
  </ol>
}

function getUnorderedListJSX(entryContent: RichTextContent): JSX.Element {
  if (!entryContent || (entryContent && !entryContent.content)) {
    return <></>;
  }

  return <ul>
    {entryContent?.content?.map((content, i) => {
      return content?.content?.map((listItemContent) => {
        return <li key={`unordered-list-${i}-${listItemContent?.value?.substring(0, 30)}`}>{getJSXForRichTextEntryContent(listItemContent)}</li>
      });
    })}
  </ul>
}

function getBlockQuoteJSX(entryContent: RichTextContent): JSX.Element {
  if (!entryContent || (entryContent && !entryContent.content)) {
    return <></>;
  }

  return (<blockquote>
    <p>{entryContent?.content?.map(content => content.value).join('\n')}</p>
  </blockquote>);
}

function geEmbeddedImageJSX(entryContent: RichTextContent): JSX.Element {
  if (!entryContent || (entryContent && !entryContent.content)) {
    return <></>;
  }

  return (<figure>
    <img
      className="w-full rounded-lg"
      src={(entryContent.data.target as unknown as Asset).fields.file.url}
      alt={(entryContent.data.target as unknown as Asset).fields.title}
      width={1310}
      height={873}
    />
  </figure>);
}

function getHeading1JSX(entryContent: RichTextContent): JSX.Element {
  if (!entryContent || (entryContent && !entryContent.content)) {
    return <></>;
  }

  return <h1>{entryContent?.content?.map(content => content.value).join('\n')}</h1>
}

function getHeading2JSX(entryContent: RichTextContent): JSX.Element {
  if (!entryContent || (entryContent && !entryContent.content)) {
    return <></>;
  }

  return <h2>{entryContent?.content?.map(content => content.value).join('\n')}</h2>
}
function getHeading3JSX(entryContent: RichTextContent): JSX.Element {
  if (!entryContent || (entryContent && !entryContent.content)) {
    return <></>;
  }

  return <h3>{entryContent?.content?.map(content => content.value).join('\n')}</h3>
}
function getHeading4JSX(entryContent: RichTextContent): JSX.Element {
  if (!entryContent || (entryContent && !entryContent.content)) {
    return <></>;
  }

  return <h4>{entryContent?.content?.map(content => content.value).join('\n')}</h4>
}
function getHeading5JSX(entryContent: RichTextContent): JSX.Element {
  if (!entryContent || (entryContent && !entryContent.content)) {
    return <></>;
  }

  return <h5>{entryContent?.content?.map(content => content.value).join('\n')}</h5>
}
function getHeading6JSX(entryContent: RichTextContent): JSX.Element {
  if (!entryContent || (entryContent && !entryContent.content)) {
    return <></>;
  }

  return <h6>{entryContent?.content?.map(content => content.value).join('\n')}</h6>
}
