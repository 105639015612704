import { TerminalIcon } from "@heroicons/react/outline";
import { SparklesIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import FaqSection from "../components/faq-section/faq-section.component";
import HowItWorksTimeline from "../components/how-it-works/how-it-works-timeline.component";
import LayoutComponent from "../components/layout/layout.component";
import OurTestscomponent from "../components/our-tests/our-tests.component";

export default function HowItWorksPage() {
  return (<LayoutComponent>
    <div className="bg-white py-16">
      <div className="max-w-7xl mx-auto pb-16 px-4 sm:px-6 lg:pb-20 lg:px-8">
        <div className="text-base max-w-prose mx-auto lg:max-w-none pb-12">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Product</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            How it works
          </p>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="inline-flex w-full justify-center px-4 py-2 sm:col-start-2 sm:text-sm">
          <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-md bg-indigo-600">
                  <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Scale your hiring</h2>
                <p className="mt-4 text-lg text-gray-500">
                  We will handle the grunt work from assessing software engineers.
                  Projects are hosted privately on GitHub, so there's no chance of others seeing the tests that you use.
                  You will always be in the loop with instant notifications and live progress reports on interviews.
                </p>
                <div className="mt-6">
                  <Link
                    to="/pricing"
                    target={'_blank'}
                    className="inline-flex rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                  >
                    Get started
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 inline-flex w-full justify-center px-4 py-2 sm:col-start-1 sm:mt-0">
            <HowItWorksTimeline />
          </div>
        </div>
        <div className="mt-12 sm:mt-12 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="inline-flex w-full justify-center px-4 py-2 sm:col-start-2 sm:text-sm">
            <OurTestscomponent />
          </div>
          <div className="mt-12 inline-flex w-full justify-center px-4 py-2 sm:col-start-1">
            <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-md bg-indigo-600">
                  <TerminalIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Test any skill</h2>
                <p className="mt-4 text-lg text-gray-500">
                  We have a library of pre-built tests for you to use straight away. From the open-ended tests, where the caniddate can use any language they want, to the focused tests that
                  provide the candidate with and empty function to complete using a specific language or framework, we have it all.
                </p>
                <p className="mt-4 text-lg text-gray-500">
                  We have three types of tests: code projects; code reviews; and bug fixes. If you already have a test that you want to use, we can add that to your library of tests to use with candidates.
                </p>
                <div className="mt-6">
                  <Link
                    to="/pricing"
                    target={'_blank'}
                    className="inline-flex rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                  >
                    Get started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqSection />
      </div>
    </div>
  </LayoutComponent>)
}
