import { Link } from "react-router-dom";
import LayoutComponent from "../components/layout/layout.component";

export default function FixingTechnicalInterviewsPage() {
  return (
    <LayoutComponent>
      <div className="py-16 bg-gray-50 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-none">
            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">About DevScreen</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Our thoughts on software engineering interviews
            </p>
          </div>
          <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <p className="text-gray-500">
              Did you know that Stanford run a <a href="https://web.stanford.edu/class/cs9/" target={"_blank"}>ten-week class</a> dedicated to
              teaching Computer Science students how to do a technical interview? “Shouldn't the rest of their degree teach them that?”, you ask.
              It should, I answer. The problem is that Software Engineering interviews often just test how good a candidate is at interviews, not
              the job itself. We've looked at some of the research as well as candidate's lived experiences and this
              text will look at three of the most important problems and three ways to create a brilliant interview experience for the interviewers
              and candidates.
            </p>
          </div>
          <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <h3>Problem 1: Relevance</h3>
            <blockquote className="twitter-tweet">
              <p lang="en" dir="ltr">Google: 90% of our engineers use the software you wrote (Homebrew), but you can't invert a binary tree on a whiteboard so fuck off.</p>&mdash; mxcl.eth (@mxcl) <a href="https://twitter.com/mxcl/status/608682016205344768?ref_src=twsrc%5Etfw">June 10, 2015</a>
            </blockquote>
            <p className="text-gray-500">
              Cracking the Coding interview is a great book, but it shouldn't need to exist. It's used by thousands of engineers to prepare for coding interviews.
              Call me old fashioned, but thousands of engineers who, presumably, know how to code and do so every day should not need to read a 500 page book on the topic before doing an interview.
            </p>
          </div>
          <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <h3>Problem 2: It's puts some awesome candidates at a disadvantage</h3>
            <p className="text-gray-500">
              Interviews take a lot of time for a candidate. Above, we spoke about one aspect of this: the need to spend weeks (even months!) practicing before even applying for a job.
              Not everybody has time to do this outside of work. Great candidates who are caring for ill or infirm family members, or those with young children will not have time for this and you risk missing out on them unnecesarily.
            </p>
          </div>
          <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <h3>How to fix it</h3>
            <p className="text-gray-500">
              Luckily, there are some great alternatives.

              1: Use realistic problems.
              You can use one of DevScreen's tests here or upload a test of your own.
            </p>
          </div>
          <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <h3>To sum it up...</h3>
            <p className="text-gray-500">
              Are software interviews broken? No, that’s a little hyperbolic. But they do have a lot of room for improvement. That's where we come in.
            </p>
          </div>
          <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <div className="relative">
              <h3 className="mt-10 text-base text-indigo-600 font-semibold">Ready to try it out?</h3>
              <div className="mt-5 flex text-base max-w-prose mx-auto lg:max-w-none">
                <div className="rounded-md shadow ">
                  <Link
                    to={'/pricing'}
                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    View pricing
                  </Link>
                </div>
                <div className="rounded-md shadow ml-4">
                  <a
                    href="mailto:sales@devscreen.io?subject=I would like a demo of DevScreen"
                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50"
                  >
                    Talk to sales
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
}
