import React from  'react';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import LayoutComponent from "../components/layout/layout.component";

export default function CreatingAwesomeInterviewProcessesPage() {
  return (
    <LayoutComponent>
      <div className="py-16 bg-gray-50 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-none">
            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Resources</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              How to create an awesome interview process
            </p>
          </div>
          <div className='prose prose-indigo text-gray-500 mx-auto lg:max-w-none'>
            <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
              <p className="text-gray-500">
                I've interviewed hundreds of candidates for software engineering positions over my career. As a result, I've learned some important things about how to run an effective interview process to make sure you hire the right person and provide a great experience to candidates. Let’s look at some:
              </p>
            </div>
            <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72 ">
              <h3>Tailor the interview for the role</h3>
              <p className="text-gray-500">
                An interview process isn't one size fits all. When it comes to interviewing candidates, you should customize your process to match the role you're hiring for and the skillset required for it. Some ways to do this are:
              </p>
              <ul className="text-gray-500">
                <li>Give them a proposed solution to a problem and ask them to code review it to improve it and find gaps</li>
                <li>Take a problem that your team has recently solved and ask a candidate to discuss how they would solve it</li>
                <li>Give candidates a similar problem to solve (i.e.: if you deal with lots of geospatial data, find an open source data set and ask the candidate to visualize or analyze it in a certain way)</li>
              </ul>
              <p className="text-gray-500">
                One key thing to remember that the candidate (in most cases) is not getting paid for their time interviewing with you, so you need to respect their time and definitely make sure you don’t ask the candidate to actually solve business problems for you unless you’re paying them.
              </p>
            </div>
            <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
              <h3>Bonus: tailor the interview for the candidate</h3>
              <p className="text-gray-500">
                Bonus points if you tailor the interview to the candidate, too. Some candidates get anxious at the thought of drawing solutions on a whiteboard in front of people they haven’t met before and would perform much better with a take home project. Others may prefer pair-programming. Flexibility here will ensure that each candidate can present the best version of themselves.
              </p>
            </div>
            <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
              <h3>Know what you’re looking for and what compromises you’re willing to make</h3>
              <p className="text-gray-500">
                If you're looking for someone with a specific skill that you cannot compromise on, make a good effort to ensure that they have it before the technical interview. For example, if you're hiring someone who knows how to code at a high standard in C++, make sure that they are capable of that before asking them to complete a technical challenge or else it will be time-consuming and frustrating for all involved. That doesn’t mean only seeking candidates with C++ experience, but it likely rules out somebody who has only ever worked with JavaScript on the frontend.
              </p>
              <p className="text-gray-500">
                A caveat to the above paragraph is to remember that awesome candidates amy not tick every box on the job description. You should <a href="https://matt-rickard.com/hire-slope-not-intercept" target='_blank'>hire for the slope, not the y-intercept</a>.
              </p>
            </div>
            <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
              <h3>Use a realistic problem to test their skills</h3>
              <p className="text-gray-500">
                We want to give our applicants a chance to explain their approach, solution, and why they selected this particular approach. This is how we find out how much they understand the problem and where their strengths lie.
              </p>
              <p className="text-gray-500">
                This also lets you see the candidate’s ability to explain their thought process in an understandable way so that others can understand. This is an important attribute of a software engineer working in a team.
              </p>
              <p className="text-gray-500">
                They should be able to explain the trade-offs made in choosing the solution when there are multiple possible solutions available for solving a problem.
              </p>
            </div>
            <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
              <h3>Allow the candidates to show the best version of themselves</h3>
              <p className="text-gray-500">
                It is important to use a realistic problem based on you and your team’s experience at the company. The candidate should be able to solve the problem based on their knowledge and experience with software engineering, not from weeks spent practicing algorithm puzzles.
              </p>
              <p className="text-gray-500">
                There’s a balancing act on the difficulty of the problem that you give the candidate. It can’t be too hard, otherwise there is no way for the candidate to demonstrate their skills in a reasonable time frame. It should be just difficult enough to make you confident in their abilities.
              </p>
            </div>
            <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
              <h3>In summary</h3>
              <ul className="text-gray-500">
                <li>Set clear expectations for candidates. You want them to know what they're getting into, so be sure to set realistic expectations about the job and the interview process so there are no surprises and the candidate can put their best foot forward.</li>
                <li>Use a problem that mirrors real-world situations in your company. <a href='https://devscreen.io' target='_blank'>DevScreen</a> have a library of tests that you can use, or better yet, upload your own! For example: if you’re a quantitate trading firm, a project that tests a candidate’s ability to process and visualise real time data would be a great.</li>
                <li>Give applicants a chance to explain their solutions, even if they don't use the perfect methodology or tools (or whatever else). Interviews are short, the candidate will have to make some tradeoffs to solve the problem in the time given</li>
              </ul>
            </div>
            <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
              <h3>Conclusion</h3>
              <p className="text-gray-500">
                Companies need to be clear about what they're looking for in their candidates and tailor their interview process accordingly. You should make sure that the problems you give them are relevant and fair to the candidate’s time. In addition, there should always be opportunities for candidates to explain their answers so that hiring managers can see how they approach problems and how they’ll perform on the job.
              </p>
            </div>
          </div>
          <div className="relative text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <div className="relative">
              <h3 className="mt-10 text-base text-indigo-600 font-semibold">Try DevScreen today:</h3>
              <div className="mt-5 flex text-base max-w-prose mx-auto lg:max-w-none">
                <div className="rounded-md shadow ">
                  <Link
                    to={'/pricing'}
                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    View pricing
                  </Link>
                </div>
                <div className="rounded-md shadow ml-4">
                  <a
                    href="mailto:sales@devscreen.io?subject=I would like a demo of DevScreen"
                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50"
                  >
                    Talk to sales
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
}
