import React from "react";
import LayoutComponent from '../components/layout/layout.component';

export default function TermsPage() {
  return (
    <LayoutComponent>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto pb-16 px-4 sm:px-6 lg:pb-20 lg:px-8 prose mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">
          <h1 className="max-w-prose mx-auto mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">TERMS OF SERVICE</h1>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Last updated: 03/22/2022</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">1.	Introduction</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Welcome to DevScreen Ltd (“Company”, “we”, “our”, “us”)! As you have just clicked our Terms of Service, please pause, grab a cup of coffee and carefully read the following pages. It will take you approximately 20 minutes.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">These Terms of Service (“Terms”, “Terms of Service”) govern your use of our web pages located at https://descreen.io operated by DevScreen Ltd.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages. Please read it here https://descreen.io/privacy.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound of them.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at support@devscreen.io so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Thank you for being responsible.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">2.	Communications</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">3.	Purchases</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected. </p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">4.	Subscriptions</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Some parts of Service are billed on a subscription basis (“Subscription(s)”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or DevScreen Ltd cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting DevScreen Ltd customer support team.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">A valid payment method, including credit card, is required to process the payment for your subscription. You shall provide DevScreen Ltd with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize DevScreen Ltd to charge all Subscription fees incurred through your account to any such payment instruments.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Should automatic billing fail to occur for any reason, DevScreen Ltd will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">5.	Free Trial</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">DevScreen Ltd may, at its sole discretion, offer a Subscription with a free trial for a limited period of time (“Free Trial”).</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">You may be required to enter your billing information in order to sign up for Free Trial.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">If you do enter your billing information when signing up for Free Trial, you will not be charged by DevScreen Ltd until Free Trial has expired. On the last day of Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">At any time and without notice, DevScreen Ltd reserves the right to (i) modify Terms of Service of Free Trial offer, or (ii) cancel such Free Trial offer.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">6.	Fee Changes</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">DevScreen Ltd, in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">DevScreen Ltd will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">7.	Refunds</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">We issue refunds for Contracts within fourteen (14) days of the original purchase of the Contract.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">8.	Content</h2>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">9.	Prohibited Uses</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(a)	In any way that violates any applicable national or international law or regulation.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(b)	For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(c)	To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(d)	To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(e)	In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(f)	To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Additionally, you agree not to:</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(a)	Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(b)	Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(c)	Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(d)	Use any device, software, or routine that interferes with the proper working of Service.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(e)	Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(f)	Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(g)	Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(h)	Take any action that may damage or falsify Company rating.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">(i)	Otherwise attempt to interfere with the proper working of Service.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">10.	Analytics</h2>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">We may use third-party Service Providers to monitor and analyze the use of our Service.</p>

          <h3 className="text-xl max-w-prose mt-2 block leading-8 font-semibold tracking-tight text-gray-900">Google Analytics</h3>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">We also encourage you to review the Google's policy for safeguarding your data: https://support.google.com/analytics/answer/6004245.</p>

          <h3 className="text-xl max-w-prose mt-2 block leading-8 font-semibold tracking-tight text-gray-900">Mixpanel</h3>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Mixpanel is provided by Mixpanel Inc.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page: https://mixpanel.com/optout/</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel: https://mixpanel.com/terms/</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">11.	No Use By Minors</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using any of Company, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the access and usage of Service.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">12.	Accounts</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">13.	Intellectual Property</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of DevScreen Ltd and its licensors. Service is protected by copyright, trademark, and other laws of the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of DevScreen Ltd.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">14.	Error Reporting and Feedback</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">You may provide us directly at support@devscreen.io with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">15.	Links To Other Web Sites</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Our Service may contain links to third party web sites or services that are not owned or controlled by DevScreen Ltd.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">DevScreen Ltd has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">YOU ACKNOWLEDGE AND AGREE THAT DEVSCREEN LTD SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">16.	Disclaimer Of Warranty </h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">17.	Limitation Of Liability</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">18.	Termination</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">If you wish to terminate your account, you may simply discontinue using Service.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">19.	Governing Law</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">These Terms shall be governed and construed in accordance with the laws of the Republic of Ireland without regard to its conflict of law provisions.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">20.	Changes To Service</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">21.	Amendments To Terms</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">22.	Waiver And Severability</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision.</p>

          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">23.	Acknowledgement</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</p>

          <h2 className="text-2xl max-w-prose mt-4 block leading-8 font-bold tracking-tight text-gray-900">24.	Contact Us</h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">Please send your feedback, comments, requests for technical support:</p>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-700 mx-auto">By email: support@devscreen.io.</p>

        </div>
      </div>
    </LayoutComponent>
  );
}
