import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {Helmet} from "react-helmet";
import HomePage from "./pages/home.page";
import './App.css';
import ErrorPage from "./pages/404.page";
import PredictableResultsPage from "./pages/use-cases/predictable-results.page";
import PricingPage from "./pages/pricing.page";
import CandidateExperiencePage from "./pages/use-cases/candidate-experience.page";
import AboutPage from "./pages/about.page";
import CandidateExperiencResourcePage from "./pages/resources/candidate-experience.page";
import PrivacyPage from "./pages/privacy.page";
import TermsPage from "./pages/terms.page";
import FixingTechnicalInterviewsPage from "./pages/fixing-technical-interviews.page";
import CreatingAwesomeInterviewProcessesPage from "./pages/creating-an-awesome-interview-process";
import HowItWorksPage from "./pages/how-it-works.page";
import RealisticTestsPage from "./pages/landing-pages/realistic-tests.page";
import BetterCxPage from "./pages/landing-pages/better-cx.page";
import BlogListPage from "./pages/blog/blog-list.page";
import BlogEntryPage from "./pages/blog/blog-entry.page";
import InclusiveLanguageCheckPage from "./pages/resources/inclusive-language-check.page";

export default function App() {
  return (<>
    <Router>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route path="/pricing">
          <PricingPage />
        </Route>
        <Route path="/how-it-works">
          <HowItWorksPage />
        </Route>
        <Route path="/company">
          <AboutPage />
        </Route>
        <Route path="/use-cases/predictable-performance">
          <PredictableResultsPage />
        </Route>
        <Route path="/use-cases/candidate-experience">
          <CandidateExperiencePage />
        </Route>
        <Route path="/resources/candidate-experience">
          <CandidateExperiencResourcePage />
        </Route>
        <Route path="/privacy">
          <PrivacyPage />
        </Route>
        <Route path="/fixing-technical-interviews">
          <FixingTechnicalInterviewsPage />
        </Route>
        <Route path="/resources/creating-awesome-interviews">
          <CreatingAwesomeInterviewProcessesPage />
        </Route>
        <Route path="/use-cases/realistic-tests">
          <RealisticTestsPage />
        </Route>
        <Route path="/use-cases/better-cx">
          <BetterCxPage />
        </Route>
        <Route path="/terms">
          <TermsPage />
        </Route>
        <Route path="/blog/:entryId">
          <BlogEntryPage />
        </Route>
        <Route path="/blog">
          <BlogListPage />
        </Route>
        <Route path="/inclusive-language-check">
          <InclusiveLanguageCheckPage />
        </Route>
        <Route component={ErrorPage} />
      </Switch>
    </Router>
  </>);
}
